
import { defineComponent, computed, watch } from "vue";
import { usePiniaStore } from "@/store/indexPinia";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useSearchStore } from "@/store/modules/search";
import { useBrandsStore } from "@/store/modules/brand";
import { useI18n } from 'vue-i18n';
import { storeToRefs } from "pinia";

export default defineComponent({
	name: "Header",
	data() {
		return {
			visible: false,
		};
	},
	setup() {
		const { language } = storeToRefs(usePiniaStore());
		const storePinia = usePiniaStore();
		const marcaStore = useBrandsStore();
		const searchStore = useSearchStore();
		const router = useRouter();

		// tradução
		const { locale } = useI18n({ useScope: 'global' })
		window.localStorage.setItem('language', locale.value+'');

		watch(locale, () =>{
			storePinia.setLanguage(locale.value+'')
			window.localStorage.setItem('language', locale.value+'');
		});

		//efeito menu
		const scrolled = ref(false);
		onMounted(() => {
			window.addEventListener("scroll", () => {
				var curr = window.pageYOffset;
				if (curr > 200) {
					return (scrolled.value = true);
				}
				if (curr === 0) {
					return (scrolled.value = false);
				}
			});
		});

		//menu mobile
		const menuMobile = ref(false);
		function onScrolled() {
			if (document.body.clientWidth <= 992) {
				menuMobile.value = !menuMobile.value;
				return menuMobile.value;
			}
		}

		//search
		const search = ref("");
		function onSubmit() {
			searchStore.postSearch({ "search-term": search.value }, language.value);
			router.push({ name: "Search" });
			search.value = "";
		}

		//contrast
		function contrastToggle() {
			return document.body.classList.toggle("bg-black");
		}

		//Font
		const fontSize = ref(1.125);
		const increaseDecrease = ref(0.063);
		function increaseFont() {
			document.body.classList.add("acessbilidade");
			if (fontSize.value + increaseDecrease.value > 2) {
				fontSize.value = 2;
			} else {
				fontSize.value = fontSize.value + increaseDecrease.value;
				document.body.style.fontSize = fontSize.value + "rem";
			}
		}
		function decreaseFont() {
			document.body.classList.add("acessbilidade");
			if (fontSize.value - increaseDecrease.value < 0.5) {
				fontSize.value = 0.5;
			} else {
				fontSize.value = fontSize.value - increaseDecrease.value;
				document.body.style.fontSize = fontSize.value + "rem";
			}
		}
		function normalFont() {
			document.body.classList.remove("acessbilidade");
			fontSize.value = 1.125;
			document.body.style.fontSize = fontSize.value + "rem";
		}

		//Marcas
		marcaStore.getBrands();
		const marcas = computed(() => marcaStore.allBrands);

		return {
			scrolled,
			onScrolled,
			menuMobile,
			onSubmit,
			search,
			contrastToggle,
			increaseFont,
			decreaseFont,
			normalFont,
			marcas,
			locale
		};
	},
});
