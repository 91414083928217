
import { defineComponent, reactive, computed, nextTick, ref  } from "vue";
import Separator from "./Separator.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import toLowerCase from "./ToLowerCase.vue";
import { useNewsletterStore } from "@/store/modules/newsletter";
import { useBrandsStore } from "@/store/modules/brand";

export default defineComponent({
	components: { Separator },
	name: "Footer",
	data () {
		return {
			publicPath: process.env.BASE_URL,
		}
	},
	setup() {
		const marcaStore = useBrandsStore();
		const newsletterStore = useNewsletterStore();

		// newsletter
		let formFooter = reactive({
			name: "",
			email: "",
			resposta: "",
		});

		const rulesFooter = computed(() => {
			return {
				name: {
					required,
					minLength: minLength(3),
				},
				email: {
					required,
					email: email,
				},
			};
		});

		const v$ = useVuelidate(rulesFooter, formFooter);

		const onSubmitNewsletterFooter = async () => {
			const result = await v$.value.$validate();
			if (result) {
				let myForm: any = document.getElementById("form-newsletter-footer");
				let formData = new FormData(myForm);
				newsletterStore.postNewsletter(formData);

				formFooter.name = "";
				formFooter.email = "";
				formFooter.resposta = "Cadastrado com sucesso";
				await nextTick()
				v$.value.$reset()
				setTimeout(() => (formFooter.resposta = ""), 5000);
			}
		};

		//busca as submarcas
		const marcasFooter = computed(() => marcaStore.allBrands);

		const toLowerCaseUrl = ref('');
		toLowerCase(toLowerCaseUrl.value);

		return {
			onSubmitNewsletterFooter,
			formFooter,
			v$,
			marcasFooter,
			toLowerCase,
		};
	},
});
