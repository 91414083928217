export const enLocale = {
    "en": {
        "header": {
            "fale-conosco": "Contact us",
            "acessibilidade": "Accessibility",
            "alto-contraste": "High-contrast",
            "aumentar-fonte": "Increase font",
            "tamanho-normal": "Normal size",
            "diminuir-fonte": "Shrink font",
            "institucional": "Institutional",
            "nossa-historia": "Our History",
            "nossas-lojas": "Our Shops",
            "jornada-asg": "Journey ASG",
            "parceria-iniciativa": "Partnership / Initiative",
            "exportacao": "Export",
            "imprensa": "Media",
            "nossas-marcas": "Our Brands",
            "receitas": "Recipes",
            "noticias": "News",
            "area-kids": "Area kids",
            "academia-do-queijo": "Cheese Academy",
            "trabalhe-conosco": "Work with us",
            "voltar": "Back",
            "procurar": "Search..."
        },
        "footer": {
            "empresa": "A group company",
            "fale-conosco": "Contact us",
            "institucional": "Institucional",
            "nossa-historia": "Our History",
            "nossas-lojas": "Our Shops",
            "jornada-asg": "Journey ASG",
            "parceria-iniciativa": "Partnership / Initiative",
            "exportacao": "Export",
            "imprensa": "Media",
            "nossas-marcas": "Our Brands",
            "receitas": "Recipes",
            "noticias": "News",
            "area-kids": "Area kids",
            "trabalhe-conosco": "Work with us",
            "atendimento": "Customer Service (SAC)",
            "contato-dados": "Contact with Personal Data Controller",
            "newsletter": "Sign up for our newsletter: ",
            "seu-nome": "Your name",
            "seu-email": "Your e-mail",
            "registre-se": "Register",
            "politica-de-privacidade": "Privacy Policy",
            "etica": "Code of Ethics",
            "linha-etica": "Line Ethics",
            "todos-direitos-reservados": "All rights reserved",
        },
        "home": {
            "nossas-marcas": "Our Brands",
            "nossa-historia": "Our History",
            "receitas": {
                "titulo": "Cook vigorously",
                "descricao": "The best recipes for you to make your day to day even more delicious. Enjoy!",
                "botao": "See all"
            },
            "trabalhe": {
                "titulo": "Work with us",
                "descricao": "View available jobs at Vigor",
                "botao": "Know more"
            },
            "profissional": {
                "titulo": "Professional",
                "titulo-2": "Line",
                "descricao": "Products designed for your business",
                "botao": "Know more"
            },
            "carousel-nossa-historia": {
                "carousel-1": "Milk powder for industrial purposes",
                "carousel-2": "Vigor launches the delicious Condensed Milk",
                "carousel-3": "Vigor becomes a pioneer in the distribution of pasteurized and bottled milk in São Paulo",
                "carousel-4": "Inauguration of a factory in Paraisópolis and in 1940 in São Gonçalo do Sapucaí, to produce Minas cheese and Parmesan cheese respectively.",
                "carousel-5": "Launch of the first natural yogurt in Brazil. That same year, milk in a 1 liter glass bottle was launched. Years later, Vigor brings plastic film packaging to the market",
                "carousel-6": "Vigor launches new yogurt flavors with fruit pulp.",
                "carousel-7": "Vigor is one of the first to launch sterilized milk with UHT technology on the market.",
                "carousel-8": "A pioneer in the launch of natural yogurt with cereals, Mix Vigor.",
                "carousel-9": "Vigor launches an innovative mix in the Spreads market, the first Blend of Butter with Margarine, Vigor Mix.",
                "carousel-10": "Vigor launches Vigorzinho, its petit suisse line.",
                "carousel-11": "The consumer is introduced to Vigor Fermented Milk.",
                "carousel-12": "The Vigor brand launches its mayonnaise line and expands its margarine line even further.",
                "carousel-13": "The Vigor Grego line arrives to definitely change the concept of yogurt.",
                "carousel-14": "Extension of the Vigor Grego portfolio for a Zero Fat and Zero Added Sugar version.",
                "carousel-15": "Launch of economical Vigor Liquid Yogurt packaging and renewal of Vigor children's line.",
                "carousel-16": "In 2015, Vigor revolutionized the way we eat margarine, launching its butter-flavored margarine. A product that fell in love with consumers.",
                "carousel-17": "Available in three different flavors, Vigor Grego Pedaços has hit the markets: the first and only yogurt with pieces of real fruit without dyes or flavorings.",
                "carousel-18": "Vigor launches the Viv brand that mixes flavor and lightness for those who want to maintain a healthy diet, but without giving up enjoying a delicious yogurt, having in its portfolio a variety of products to match all occasions of the day.",
                "carousel-19": "Vigor and local producers unite to bring the tradition of artisanal cheese to more homes in Brazil. Viv launches the new UHT protein, a dairy drink designed for those who practice sports and like to take care of their health, without losing the habit of consuming a healthy and tasty product. Our UHT contains 15g of proteins to help with your diet, it does not contain lactose or addition of sugars and also contains Whey Protein, leaving everything in balance.",
            }
        },
        "ourHistory": {
            "titulo": "Pioneering, Tradition and Experience",
            "paragrafo": "Concern to meet the wishes and needs of its consumers at all levels. Rigorous attention to the quality of all its products, from processing to display at the point of sale. These are virtues that have made Vigor a reference in the Brazilian food sector and contribute to its constant evolution, often placing it ahead of its time.",
        },
        "ourStores": {
            "horarios": "Opening hours",
            "dia-1": "Monday to Thursday: ",
            "horario-1": "7:30 am to 6:00 pm",
            "dia-2": "Friday: ",
            "horario-2": "6:30 am to 6:30 pm",
            "dia-3": "Saturday: ",
            "horario-3": "9:00 am to 1:00 pm",
            "paragrafo": "Vigor Alimentos stores are always open to welcome you. In them you will find products from all our brands, in an environment where the quality of products and service are the great offers. Welcome!",
            "loja": "Store",
            "atendimento": "Opening hours",
            "domingo": "Sunday and Holidays - Closed",
            "loja-1": {
                "horario-1": "Monday – Friday 07:00 am – 6:30pm",
                "horario-2": "Saturday   – 08:00 am – 2:30 pm",
            },
            "loja-2": {
                "horario-1": "Monday – Friday 07:00 am – 7:00 pm",
                "horario-2": "Saturday   – 07:00 am – 2:30 pm",
            },
            "loja-3": {
                "horario-1": "Monday – Friday 08:00 am – 6:00 pm",
                "horario-2": "Saturday  – 08:00 am – 12:00 am",
            },
            "loja-4": {
                "horario-1": "Monday – Friday 08:00h – 6:00 pm",
                "horario-2": "Saturday, Sunday and Holidays – Closed",
            },
        },
        "asg": {
            "governanca": {
                "titulo": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a dictum dolor, nec tincidunt tortor. Etiam euismod vehicula consequat. Vivamus placerat in elit sed dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a dictum dolor, neipsum.tincidunt tortor. Etiam euismod vehicula consequat. Vivamus placerat in elit sed dictum. Lorem ipsum dolor sit amet, consectetur adipiscing.",
                "financeira": {
                    "titulo": "Financial statements",
                    "descricao": "Vigor prioritizes and reinforces its commitment to maintain total transparency in its relationship with its investors. Therefore, we present for consultation our annual audited financial statements.",
                    "sub-titulo-1": "Access the financial statements",
                    "sub-titulo-2": "Other relevant publications",
                    "botao": "Relevant fact Nº 01 - 2021",
                },
                "etica": {
                    "titulo": "Code of ethics",
                    "descricao": "The Code of Ethics is the document that guides us on VIGOR's expectations in relation to the main rules of conduct and ethical principles, always aligned with VIGOR's values. It applies to all VIGOR employees and third parties who act on VIGOR's behalf and benefit. The Code of Ethics also guides us regarding compliance with laws and VIGOR's internal rules.",
                    "titulo-2": "Ethics Line",
                    "descricao-2": "In case of any situation of non-compliance with laws and internal rules, we ask that you report the fact through the Ethics Line, in one of the channels provided below: ",
                    "titulo-3": "For efficient communication, we ask that you always include the following information: ",
                    "lista-1": "How did it happen",
                    "lista-2": "who happend?",
                    "lista-3": "When did it happen?",
                    "lista-4": "Where did it happen?",
                    "lista-5": "Who are the people involved?",
                    "titulo-4": "The Ethics Line is managed by the independent company Áliant, specialist in the management of reports and denouncements in a confidential and anonymous way (if you prefer).",
                },
            },
        },
        "partnership": {
            "titulo": "Partnerships and Initiatives",
            "descricao": "Discover our partnerships and initiatives below.",
            "botao": "See all",
            "bloco-1": {
                "titulo": "School Project",
                "descricao": "Learn more about this educational project and register your school to participate as well.",
            },
            "bloco-2": {
                "titulo": "Instituto Germinare",
                "descricao": "Vigor supports education in Brazil to train business leaders.",
            },
            "protejo-1": {
                "titulo": "The project",
                "descricao": "For more than 30 years, Vigor has been developing the Projeto Escola, dedicated with great love and care to children. The goal is to teach children the process of making yogurt and other dairy products in a playful and fun way, through a pedagogical visit to the Vigor factory. We also developed an awareness campaign about the environment and the food pyramid, which are presented through a video with language appropriate to the age group served, in addition to explanations from the monitors, who are always willing to clarify existing doubts.",
                "titulo-2": "Activities and learning",
                "lista-1": "Recycling workshop",
                "lista-2": "Guided visit to the factory",
                "lista-3": "Importance of good nutrition",
                "lista-4": "Relationship with the environment",
                "titulo-3": "How it works?",
                "paragrafo": "The School Project is free*",
                "paragrafo-2": "and takes place throughout the school year, for children aged 6 to 12 from municipal, state, private, other institutions and groups of the elderly. It operates from Monday to Friday in two shifts, from 9:30 am to 12:00 pm and from 1:30 pm to 4:00 pm, with groups of up to 40 visitors per period. It is important to point out that we only have 80 places available per institution, giving opportunity to everyone who wants to participate. * transport not included.",
            },
            "protejo-2": {
                "titulo": "The institute",
                "paragrafo-1": "Founded in 2009, the Institute sees Education as the main instrument for transforming and developing society, and through its School, it aims to offer, at no cost, an academic education of excellence for potential young people, associated with the development of skills and abilities to to become future managers and business leaders with a broad, entrepreneurial and innovative vision for the job market and personal life.",
                "paragrafo-2": "To transmit knowledge about business management, the Germinare School, which is approved by the Ministry of Education – MEC – and recognized by UNESCO - United Nations Educational, Scientific and Cultural Organization - offers these young people ample preparation for the professional environment, with administration content focused on project management, finance, people, marketing, operations, corporate governance, strategy and logistics.",
                "paragrafo-3": "This pedagogical project is exclusive, and was created based on the vision that young people need high-quality and differentiated education, in order to prepare them for the real challenges of professional life. In this sense, Germinare students carry out, on a permanent basis, business management projects, visits to the Stock Exchange, and also to productive and corporate environments, in addition to meetings with specialists. Numerous simulation activities are carried out with application in the real world, including practical work in partnership with companies.",
                "titulo-2": "The differential",
                "paragrafo-4": "Germinare's differential is based on this unprecedented academic project and also through an intense curriculum for students from the 6th year of Elementary School to the 3rd year of High School. There are 10 hours of classes per day, and during this period, students have the opportunity to combine the learning of curricular contents with the use of technology, sports, language, music, arts and study guidance.",
                "titulo-3": "Our challenge",
                "paragrafo-5": "Today, the Institute's great challenge is to expand this concept of educational training to other regions of the country, thus expanding the opportunity for a promising future for young talents throughout Brazil, and thus contributing to the formation of a new generation of leaders.",
            }
        },
        "export": {
            "titulo": "Product catalogs",
            "descricao": "To learn more, contact the responsible area by e-mail: ",
        },
        "release": {
            "titulo": "Press Office - Ketchum",
            "linkNameBase": "Read the release",
        },
        "news": {
            "titulo": "News",
            "resultado": "No search results",
            "leia": "Read too",
            "linkNameBase": "Read the new",
            "formulario": {
                "input-1": "Do your search",
                "botao": "Search",
                "filtrar": "filter",
                "data": "date",
            },
        },
        "recipes": {
            "titulo": "Cook with Vigor and make your food tastier",
            "descricao": "The best recipes for you to make your day to day even more delicious. Enjoy!",
            "categoria": "Category",
            "todas": "All",
            "botao": "See recipe",
            "interna": {
                "ingredientes": "Ingredients",
                "preparo": "Preparation mode",
                "botao": "Come back",
                "titulo-1": "Products used in this recipe",
                "titulo-2": "Discover other recipes",
            },
        },
        "kidsVigor": {
            "bloco-1": {
                "titulo": "Ready for your most fun family time?",
                "descricao": "Games and videos that teach how to create fun objects reusing packaging from Vigor's children's line. Watch and discover games that stimulate creativity and teach about sustainability in a light and fun way."
            },
            "bloco-2": {
                "titulo": "Download the Fun Handbook",
                "descricao": "Here you will turn your sweetest moments into very fun games. Together, you and the children will discover new ways to play.",
                "botao": "See all"
            },
            "botao": "Play now",
            "brincadeira-1": "Fun handbook",
            "brincadeira-2": {
                "titulo-1": "Puppets",
                "titulo-2": "Flycatcher Toads",
                "titulo-3": "Custom Vases",
            },
            "brincadeira-3": "Idea Factory",
            "manual": {
                "descricao": "Here you will turn your sweetest moments into very fun games. Together, you and the children will discover new ways to play.",
                "descricao-2": "Ready for your most fun family time?",
                "botao": "Download!",
                "titulo": "Children's Line Products",
                "carousel-1": "Fun fishing",
                "carousel-2": "Boat comes and goes",
                "carousel-3": "Vig shark",
                "carousel-4": "Monster train",
                "carousel-5": "Yellow bowling",
                "carousel-6": "Stretch and Spear",
                "carousel-7": "Vig Monster",
                "carousel-8": "Hash",
                "carousel-9": "Phone",
                "carousel-10": "Left Hand, Right Hand",
            },
            "fabrica-de-ideias": {
                "titulo": "WOW!",
                "descricao": "Look how handsome! This is how the toy that you can make at home will look like. Get to work!",
                "titulo-2": "What will you need",
                "descricao-2": "In addition to a lot of creativity, see here, tim-tim by tim-tim, all the material you will need to make an incredible toy.",
            },
            "aquario": {
                "carousel-1": "A pack of Vigor Fermented Milk",
                "carousel-2": "A paper box (preferably blue)",
                "carousel-3": "Glue stick Or white glue",
                "carousel-4": "scotch tape",
                "carousel-5": "colored papers",
                "carousel-6": "blunt scissors",
                "carousel-7": "Pens and pencils for coloring",
                "carousel-8": "nylon wire",
            },
            "binocolo": {
                "carousel-1": "Hot glue",
                "carousel-2": "Pencil case",
                "carousel-3": "Scissors",
                "carousel-4": "Brushes",
                "carousel-5": "Black wool",
                "carousel-6": "Black ink",
                "carousel-7": "Colorful balloons",
                "carousel-8": "2 Vigor bottle yogurt",
            },
            "cabelinho": {
                "carousel-1": "3 packages of 3 packages of Vigor fermented milk",
                "carousel-2": "Funnel",
                "carousel-3": "Canary seed",
                "carousel-4": "Water for watering",
                "carousel-5": "A handful of earth",
            },
            "nave-espacial": {
                "carousel-1": "1 pack of fermented milk",
                "carousel-2": "2 packs of force mix",
                "carousel-3": "2 packs of vig juice",
                "carousel-4": "2 packs of 900g liquid yogurt",
                "carousel-5": "1 pot of vigor",
                "carousel-6": "Gray color ink",
                "carousel-7": "Two brushes",
                "carousel-8": "Hot glue",
                "carousel-9": "Scissors",
                "carousel-10": "Permanent markers and a mechanical pencil",
            },
            "cantina": {
                "titulo": "Nutritious, tasty and fun, that's what Vigor children's line products are. Now you can buy and prepare delicious snacks with ease, and you don't even have to queue.",
                "titulo-2": "Children's Line Products",
                "carousel-1": "Fermented milk",
                "carousel-2": "Family size fermented milk",
                "carousel-3": "Strawberry flavor punch",
                "carousel-4": "Vigorzinho chocolate flavor",
                "carousel-5": "Children's chocolate milk",
                "carousel-6": "Vig Juices",
                "carousel-7": "Mix Vigor",
            },
            "colecoes": {
                "titulo": "Have fun getting to know Vigor's children's line collections.",
                "titulo-2": "There are several themes to play and have fun.",
                "carousel-1": {
                    "titulo": "TOGETHER ON THE FIELD ",
                    "ano": "2022",
                    "descricao": "VIGOR ENTERS THE FIELD TO RAISE THE CROWD"
                },
                "carousel-2": {
                    "titulo": "MINIONS 2",
                    "ano": "2022",
                    "descricao": "MINIONS 2: THE ORIGIN OF THE GRU"
                },
                "carousel-3": {
                    "titulo": "HALLOWEEN",
                    "ano": "2021",
                    "descricao": "WITH THEM IT'S FUN AND LAUGHTER GUARANTEED!"
                },
                "carousel-4": {
                    "titulo": "SPORTS",
                    "ano": "2021",
                    "descricao": "WITH THIS FUN GROUP, PLAY IS A CHAMPIONSHIP!!"
                },
                "carousel-5": {
                    "titulo": "ACCOUNT + FUN ",
                    "ano": "2021",
                    "descricao": "WITH THIS PEOPLE WE WILL ADD, SUBTRACT, DIVIDE AND MULTIPLY PLAYING!"
                },
                "carousel-6": {
                    "titulo": "MONSTERS ",
                    "ano": "2020",
                    "descricao": "YOUR HOTTEST FUN WITH A MONSTROUS CROWD!"
                },
                "carousel-7": {
                    "titulo": "BACK TO SCHOOL ",
                    "ano": "2020",
                    "descricao": "VIGOR STARTS THE YEAR IN THE WORLD OF IMAGINATION TO START PLAYING."
                },
                "carousel-8": {
                    "titulo": "TOGETHER ON THE FIELD ",
                    "ano": "2019",
                    "descricao": "VIGOR ENTERS THE FIELD TO RAISE THE CROWD!"
                },
                "carousel-9": {
                    "titulo": "TOGETHER ON THE FIELD ",
                    "ano": "2018",
                    "descricao": "THEY WILL ENTER THE FIELD TO MAKE THE WHOLE FAMILY CROWD MORE EXCITED!"
                },
                "carousel-10": {
                    "titulo": "MINION",
                    "ano": "2017",
                    "descricao": "WITH THEM IT'S FUN AND LAUGHTER GUARANTEED!"
                },

            },
            "compre": "Buy now",
        },
        "workUs": {
            "bloco-1": {
                "paragrafo": "We are present in the routine of millions of Brazilians with products that are synonymous with pleasurable moments, as our purpose is to feed a tastier life. Because food is to nourish, encourage, inspire. The best thing is to make things lighter, healthier, happier. We are more than 4,000 people who ensure that brands such as Grego, Banda Azul, Danubio, Amélia, among others, reach the shelves of points of sale and the homes of our customers. consumers, every day. And for that, it takes a lot of vigor!"
            },
            "bloco-2": {
                "titulo": "Come be Vigor!",
                "paragrafo-1": "Ser Vigor calls for force. He asks for energy to have an attitude of ownership, inspiring everyone around us. It asks authentic people who create value through work to dream big, achieving the unimaginable. It asks for practicality to deal with the challenges of everyday life, always doing the right thing and not the easiest thing to do. Being Vigor is identifying with our values and seeking a dynamic environment, with space to create, learn in practice and be part of an intense, open team that makes things happen. Serving more than 23.6 million homes and approximately 60 million people per year in the country requires vigor. You have?",
                "paragrafo-2": "Being Vigor is identifying with our values and seeking a dynamic environment, with space to create, learn in practice and be part of an intense, open team that makes things happen. Serving more than 23.6 million homes and approximately 60 million people per year in the country requires vigor. You have?",
                "botao": "know more",
            },
            "valores": "Values",
            "vagas": "Our vacancies",
            "equidade": "Check the Pay Equity Report.",
        },
        "contact": {
            "titulo": "SAC",
            "paragrafo-1": "For questions, complaints or suggestions, contact: ",
            "paragrafo-2": "From Monday to Friday from 9:00 am to 5:00 pm, except holidays.",
            "paragrafo-3": "If you prefer, send an email: ",
            "titulo-2": "Social networks",
            "formulario": {
                "titulo": "Contact",
                "input-1": "Name",
                "input-2": "Email",
                "input-3": "Address",
                "input-4": "Number",
                "input-5": "Complement",
                "input-6": "Neighborhood",
                "input-7": "Zip code",
                "input-8": "State",
                "input-9": "City",
                "input-10": "Telephone",
                "input-11": "Cell",
                "input-12": "Send files",
                "input-13": "Select the Subject",
                "input-14": "Message",
                "input-15": "Enter your message",
                "botao": "Enviar",
                "opacao-1": "Criticism",
                "opacao-2": "Compliments",
                "opacao-3": "Information",
                "opacao-4": "Suggestions",
            },
        },
        "newsletter": {
            "titulo": "WANT TO STAY ON THE VIGOR?",
            "descricao": "Sign up and receive news from Vigor in your email.",
            "formulario": {
                "titulo": "Sign up for our newsletter!",
                "nome": "Name",
                "input-1": "Your name",
                "email": "Email",
                "input-2": "Your email",
                "botao": "submit",
                "descricao": "Vigor offers a newsletter service with the dissemination of information about promotions, offers, recipes and news regarding its products and the company. By choosing to subscribe to this service, you accept the processing of personal data for sending Vigor communications.                "
            }
        },
        "brand": {
            "titulo": "Select what you want to access: ",
            "bloco-1": "Products for me",
            "bloco-2": "Products for my business",
            "conheca": "Meet the",
        },
        "category": {
            "titulo-1": "Product categories",
            "titulo-2": "Check out the products",
        },
        "product": {
            "tipo": "Type",
            "sabor": "Flavor",
            "embalagem": "Packaging",
            "informacoes-tabela": "Ingredients / Nutritional information",
            "informacoes": "Product information",
            "ingredientes": "Ingredients",
            "alergicos": "Allergicos",
            "nutricional": "Nutrition Facts",
            "produtos-relacionados": "Related Products",
            "receitas": "Recipes with",
            "tabela": {
                "qtd": "Amount per portion",
                "valor-energetico": "Energy-value",
                "carboidratos": "Carbohydrates",
                "proteinas": "Proteins",
                "gorduras-totais": "Total-fats",
                "gorduras-saturadas": "saturated fats",
                "gorduras-trans": "Trans-fats",
                "gorduras-monoinsaturadas": "Monounsaturated fats",
                "gorduras-poliinsaturadas": "Polyunsaturated fats",
                "fibra-alimentar": "Dietary fiber",
                "sodio": "Sodium",
                "calcio": "Calcium",
                "fosforo": "Phosphorus",
                "ferro": "Iron",
                "zinco": "zinc",
                "vitamina-a": "Vitamin A",
                "vitamina-b1": "Vitamin B1",
                "vitamina-b2": "Vitamin B2",
                "vitamina-b": "Vitamin B",
                "vitamina-b6": "Vitamin B6",
                "vitamina-b12": "Vitamin B12",
                "vitamina-c": "Vitamin C",
                "vitamina-e": "Vitamin E",
                "acucares": "Sugars",
                "colesterol": "Cholesterol",
                "acido-folico": "Folic acid",
                "lactose": "Lactose",
                "glicose": "Glucose",
                "galactose": "Galactose",
                "frutose": "Fructose",
                "porcoes-por-embalagem": "Portions per pack",
                "porcao": "Portion",
                "titulo": "Energy value (kcal)",
                "totais": "totals",
                "adicionados": "added",
                "disclamer": "In order to always improve, our products may change. See also the information on the packaging.",
            },
        },
        "artisanCheese": {
            "bloco-1": {
                "titulo-1": "About the line",
                "titulo-2": "crafts from Brazil",
                "paragrafo-1": "The Artisan Cheeses of Brazil line is intended to democratize the consumption of specialty cheeses and encourage the growth of artisan cheeses in the marketplace.",
                "paragrafo-2": "Vigor seeks to give visibility to artisan cheeses and expand its distribution, through partnerships with local producers, in order to meet a portion of more demanding consumers, who want to know the origin of the product, demand good practices in animal production (such as welfare and sanity), food safety, and wish to try new flavors.",
                "paragrafo-3": "The Artesanais do Brasil line brings two special versions, Queijo Minas Artesanal Cerrado and Queijo Minas Artesanal Campo das Vertentes, both produced in micro-regions of Minas Gerais through carefully crafted processes.",
            },
            "bloco-2": {
                "titulo-1": "What are Artisanal Cheeses?",
                "descricao": "The great differential of artisanal cheese is that it is made with raw milk, using mainly manual techniques and by those who master the process. On the palate, the flavor is remarkable and can vary according to the region where it is made, the maturation time, and even the process used in production.",
            },
            "bloco-3": {
                "titulo-1": "Minas Artesanal Cerrado Cheese",
                "descricao-1": "Owner of the Dairy Farm that bears his name, Eudes Braga started helping his father in coffee farming at a very young age, in the Alto Paranaíba region, in the Cerrado, in Minas Gerais. With more than 13 years of experience in the cheese industry, today Eudes Braga is considered one of the largest producers of raw milk artisanal Minas cheese in the state and is proud of the business and social heritage built over these years, contributing to the development of the region and the families of the farm and dairy plant employees.",
                "titulo-2": "Cheese Minas Artesanal Campo das Vertentes",
                "descricao-2": "Produced by Marcondes Moura, at Fazenda Fortaleza, located in the city of Prados, in the Campo das Vertentes region, Minas Gerais. Operating in the cheese market for 20 years, Marcondes inherited from his father the farm, which he has been operating since 1945.",
            },
            "bloco-4": {
                "titulo-1": "Cerrado",
                "descricao-1": "Cerrado is the region of Alto Paraíba and is located in the northwest of the state of Minas Gerais.",
                "titulo-2": "Campo das vertentes",
                "descricao-2": "Campo das Vertentes is the mountainous region formed by steep slopes of mountain ranges and located in southeastern Minas Gerais.",
            },
            "bloco-5": {
                "titulo-1": "Main cheeses",
                "titulo-2": "MINAS ARTESANAL CHEESE",
                "botao": "Learn More",
            },
            "bloco-6": {
                "titulo-1": "If you are a producer",
                "descricao-1": "would like to be part of Vigor's portfolio, contact us",
                "descricao-2": "Or call",
                "botao": "Get in touch",
            },
            "inside": {
                "receitas": {
                    "descricao-1": "With a more subtle flavor and aroma than the others, they are usually very balanced cheeses in flavor. It is suggested to keep in the mouth for a little longer to appreciate the subtleties ( fresh milk, field, salt, low acidity ).",
                    "titulo-1": "Recipe suggestion for minas cheese",
                    "descricao-2": "Whether to be consumed pure, melted in snacks, accompanied by sweets and jams or even grated, artisanal cheeses are differentiated products, capable of bringing a feeling of comfort, coziness and tasty memories to consumers.",
                },
                "carousel-1": {
                    "slide-1": "Milk Candy",
                    "slide-2": "Guava",
                    "slide-3": "Turkey Breast",
                    "slide-4": "Barbecue Pork Sausage",
                    "slide-5": "Sparkling Muscat",
                    "slide-6": "Weiss Style Beer",
                },
                "botao": "View recipes",
                "tabela": {
                    "titulo": "NUTRITIONAL TABLE",
                    "sub-titulo": "Minas artisanal cheese",
                    "porcao": "Portion of",
                    "qtd": "Quant. per serving % DV",
                    "campo-1": "Energy value",
                    "campo-2": "Carbohydrate",
                    "campo-3": "Protein",
                    "campo-4": "Total fat",
                    "campo-5": "Saturated fat",
                    "campo-6": "Trans fats",
                    "campo-7": "Sodium",
                    "campo-8": "Dietary fiber",
                    "informacao": "Nutrition information",
                    "botao": "Nutrition table",
                },
            },
            "cerrado": {
                "bloco-1": {
                    "titulo": "Cerrado (Alto Paranaíba region)",
                    "descricao": "Alto Paranaíba is one of the ten planning regions of the State of Minas Gerais. It belongs, together with the Triângulo Mineiro, to the Mesorregião do Triângulo Mineiro e Alto Paranaíba.",
                },
                "bloco-2": {
                    "titulo": "Granja Leiteira Eudes Braga",
                    "descricao": "Farm with 13 years of existence, in the region of Alto Paranaíba, in the Cerrado, in Minas Gerais. Eudes Braga is considered the largest producer of artisan Minas cheese from raw milk in the State. Currently with 24 employees who perform the activities in the Farm, from milk production, cheese manufacturing, logistics and marketing, among them exclusive veterinarians.",
                },
                "bloco-3": {
                    "titulo": "Consumption suggestion for minas cheese from the cerrado",
                },
                "carousel-1": {
                    "slide-1": "Cheese Bastettes with Guava Ketchup",
                    "slide-2": "Cornmeal cake",
                    "slide-3": "Choripán with Chimichurri",
                    "slide-4": "Curau Cake",
                    "slide-5": "Minas Cheese Bread",
                    "slide-6": "Petit Gateau Romeo and Juliet",
                    "slide-7": "Orange Cheese Pudding",
                    "slide-8": "Queijadinha",
                    "slide-9": "Risoto à Mineira",
                    "slide-10": "Tapioca de Queijo Minas w/ Turkey Breast",
                },
            },
            "campo-das-vertentes": {
                "bloco-1": {
                    "titulo": "Campos das Vertentes (Microregion of São João Del Rei)",
                    "descricao": "Campo das Vertentes is one of the twelve mesoregions of the state of Minas Gerais. The region is mountainous, being part of the Serra da Mantiqueira. The slopes of the mountains in the region drain water from springs, streams and creeks. Their waters flow into rivers, which contribute to the formation of the Paraíba do Sul, São Francisco and Paraná Basins. Its native fields are characterized by low vegetation, formed on the slopes of the mountains, hence the name Campo das Vertentes (Fields of Vertents).",
                },
                "bloco-2": {
                    "titulo": "Farm Fortaleza",
                    "descricao": "Produced by Marcondes Moura, at Fazenda Fortaleza, located in the city of Prados, in the Campo das Vertentes region, in Minas Gerais. He has been working in the cheese market for 20 years, Moura inherited from his father the farm, which he has been operating since 1945.",
                },
                "bloco-3": {
                    "titulo": "Consumption suggestion for Campo das Vertentes cheese",
                    "descricao": "With a more subtle flavor and aroma than the others, they are usually very balanced cheeses in flavor. It is suggested to keep in the mouth for a little longer to appreciate the subtleties (fresh milk, countryside, salt, low acidity).",
                },
                "carousel-1": {
                    "slide-1": "Baião de dois",
                    "slide-2": "Potato with ribs",
                    "slide-3": "Carbonara à mineira",
                    "slide-4": "Tenderloin with cheese sauce",
                    "slide-5": "Hamburguer mineiro",
                    "slide-6": "Floor sandwich",
                    "slide-7": "Campo das Vertentes cheese board",
                    "slide-8": "Biscoito mineiro",
                    "slide-9": "Cheesecake from the countryside",
                    "slide-10": "Rosti potato",
                },
            },
        },
        "vivProtein": {
            "lista-1": {
                "titulo": "VIV PROTEIN UHT",
                "item-1": "15g protein",
                "item-2": "9 essential amino acids",
                "item-3": "Glutamine",
                "item-5": "Zero added sugars and lactose",
                "item-6": "Ideal for a pre/post workout!",
            },
            "lista-2": {
                "titulo": "VIV PROTEIN IOGURTE LÍQUIDO",
                "item-1": "15g of protein",
                "item-2": "Assists in muscle recovery",
                "item-3": "With Whey",
                "item-4": "Zero added sugars and lactose",
            },
            "carousel-1": {
                "item-1": {
                    "titulo": "VIGOR VIV PROTEIN UHT",
                    "descricao": "UHT beverage with 15g of protein and delicious flavors!",
                    "descricao-2": "Free of lactose and added sugars, ideal for a pre/post workout",
                    "descricao-3": "In addition, Vigor VIV Protein UHT features: ",
                    "item-1": "Whey protein and casein",
                    "item-2": "9 essential amino acids",
                    "item-3": "Glutamine and BCAA.",
                },
                "item-2": {
                    "titulo": "VIGOR VIV",
                    "titulo-2": "PROTEIN IOGURTE LÍQUIDO",
                    "descricao": "With 15g of protein per serving, the highest in the market, Vigor VIV Protein Liquid Yogurt helps muscle recovery, besides being zero added sugars and lactose is perfect for any time of your day, giving an extra strength and that feeling of satiety.",
                },
            },
            "botao": "Nutritional tabel",
            "tabela-nutricional": "NUTRITIONAL TABLE",
            "ingredientes": "Ingredients",
            "alergicos": "Allergic",
        },
        "buscar": {
            "input": "Do your search...",
            "botao": "Search",
            "botao-2": "Learn more",
            "resultado-1": "Your search results",
            "resultado-2": "We didn't find any results for your search!",
            "resultado-3": "Other results that might be useful: ",
            "noticias": "News",
            "realeases": "Realeases",
            "receitas": "Recipes",
            "marcas": "Brands",
            "produtos": "Products",
        },
        "privacyPolicy": {
            "titulo-principal": "EXTERNAL PRIVACY STATEMENT",
            "bloco-1": {
                "titulo": "Introduction",
                "paragrafo-1": "Within VIGOR, we are committed to the security of your personal data and your privacy throughout your journey within VIGOR's institutional website and its brands, from the moment you access our website, register on our platforms, during commercial contacts with suppliers and potential customers to support when you become our customer or supplier and when you are a consumer contacting us or participating in our promotional campaigns.",
                "paragrafo-2": "When you contact us through our official consumer relationship channels, visit our facilities, apply for vacancies or through professional communications between VIGOR and its customers and suppliers, you will be asked for some information so that we can provide you with the best service.",
                "paragrafo-3": "Please note that we may update this Privacy Notice at any time, upon notice on the website. We recommend that you consult this document periodically to stay informed of how VIGOR is treating your personal data.",
            },
            "bloco-2": {
                "titulo": "Goals",
                "paragrafo-1": "This Privacy Notice aims to explain in a clear and transparent way how VIGOR collects and uses personal data, describe the rights of the Holder of personal data and demonstrate our specific practices regarding the processing of personal data.",
            },
            "bloco-3": {
                "titulo": "Document Content",
                "titulo-1": "What You Need to Know Before Reading the Policy",
                "paragrafo-1": "In September 2020, the General Data Protection Law, Federal Law No. 13.709/2018 (“LGPD”) began to regulate the processing of personal data and sensitive personal data of holders.",
                "titulo-2": "But what is personal data?",
                "paragrafo-2": "Personal data is any information related to an identified or identifiable natural person, that is, information directly linked to a natural person or information that makes it possible to identify a natural person.",
                "titulo-3": "What is sensitive data?",
                "paragrafo-3": "Some categories of personal data are considered sensitive and deserve special attention. Data considered sensitive are all data on racial or ethnic origin, religious conviction, political opinion, union affiliation or organization of a religious, philosophical or political nature, data referring to health or sex life, genetic or biometric data.",
                "titulo-4": "Who owns the personal data?",
                "paragrafo-4": "Every natural person whom personal data and sensitive personal data can identify. Therefore, all operations carried out with any information that makes it possible to identify an individual must observe the legal requirements of the LGPD.",
                "titulo-5": "What Data We Collect and Its Purposes",
                "paragrafo-5": "The processing of personal data can occur in different ways within Vigor, so we bring this division by categories of holders to better inform you about the ways of processing personal data in our processes.",
                "titulo-6": "Vigor Page Visitors",
                "paragrafo-6": "When you browse the websites of VIGOR and its brands, we collect “Cookies”, which are small text files generated, sent and stored on your own computer with the function of identifying, tracking and storing data related to your Internet browsing. More information about the processing of this data can be found in our Cookie Policy.",
                "paragrafo-7": "On our websites there are also fields for you to subscribe to our newsletter, if you want to receive promotional or institutional information from Vigor, or to participate in surveys carried out by our brands.",
                "titulo-7": "Consumers",
                "paragrafo-8": "Consumer data may be collected when you access the VIGOR website and register for our newsletter to receive promotional information, when you contact VIGOR directly through our customer relationship channels, when you subscribe to our campaigns marketing and/or promotions for participation in sweepstakes, incentives or discounts, or through third-party platforms such as Reclame Aqui or Procon regarding our products.",
            },
            "tabela-1": {
                "titulo": "Data categories",
                "titulo-item-1": "First name;",
                "titulo-item-2": "Contact Information ",
                "descricao-item-2": " - E-mail.",
            },
            "tabela-2": {
                "titulo": "Goal",
                "titulo-item-1": "Promotional newsletter",
                "descricao-item-1": "VIGOR intends to send news related to the brand, as well as eventual Marketing campaigns to those interested in launching products and campaigns of existing brands .",
            },
            "tabela-3": {
                "titulo": "Data categories",
                "titulo-item-1": "Full name;",
                "titulo-item-2": "Identification generated by official bodies",
                "descricao-item-2": " - CPF.",
                "titulo-item-3": "Contact Information ",
                "descricao-item-3": " - Home Address 🇧🇷 Home Phone {'|'} 	Personal Mobile Number {'|'} Personal email {'|'} Social media;",
                "titulo-item-4": "Professional information ",
                "descricao-item-4": " - Occupation/Title {'|'} Business address {'|'} Commercial phone;",
                "titulo-item-5": "Financial Information ",
                "descricao-item-5": " - Banking Data (Bank, Branch and Account).",
            },
            "tabela-4": {
                "titulo": "Goal",
                "titulo-item-1": "Customer service",
                "descricao-item-1": "When the consumer or customer contacts the SAC to complain, praise, suggest, ask for information, etc., we record the data.",
                "descricao-item-2": "When it comes to a complaint, the product can be exchanged at the consumer's residence or a new product sent through the Post Office. The collected data is shared with the companies that provide the exchange service.",
                "descricao-item-3": "In some cases, instead of exchanging the product at the consumer's residence, we make the refund through a bank account.",
            },
            "tabela-5": {
                "titulo": "Data categories",
                "titulo-item-1": "Name and initials ",
                "descricao-item-1": " - First Name {'|'} Middle name {'|'} Last name;",
                "titulo-item-2": "Personal Characteristics ",
                "descricao-item-2": " - Age {'|'} Genre;",
                "titulo-item-3": "Identification generated by official bodies ",
                "descricao-item-3": " - CPF {'|'} RG;",
                "titulo-item-4": "Home Information ",
                "descricao-item-4": " - Home Address {'|'} Home Phone {'|'} Personal Mobile Number {'|'} Personal email.",
            },
            "tabela-6": {
                "titulo": "Goal",
                "titulo-item-1": "Promotions",
                "descricao-item-1": "We hire third-party companies that support us in carrying out promotional actions. The information is collected so that we can have the identification of the winner for delivery of the promotion prize.",
            },
            "bloco-4": {
                "descricao": "The legal grounds for processing Vigor's customers' personal data are: ",
                "titulo-1": "Compliance with a legal or regulatory obligation: ",
                "descricao-1": "when required by law or regulatory body to process personal data;",
                "titulo-2": "Consent: ",
                "descricao-2": "in cases where we ask you to authorize the treatment, such as sending email marketing and satisfaction surveys.",
            },
            "bloco-5": {
                "titulo": "Individuals who Correspond with Vigor via email",
                "paragrafo-1": "If the Holder corresponds by email with a recipient of Vigor, their emails will be inspected by the security tools of Vigor's IT infrastructure, which may result in the content being read by authorized persons of Vigor other than the intended recipient .",
                "paragrafo-2": "The legal grounds for processing the personal data of individuals who correspond with Vigor by email: ",
                "paragrafo-3": "Legitimate interest: ",
                "paragrafo-4": "in protecting IT infrastructure from unauthorized access or data leakage and in analyzing email traffic.",
            },
            "bloco-6": {
                "titulo": "Job Seekers",
                "paragrafo-1": "Vigor collects information about candidates regarding job opportunities available in the company directly from the holder or through partner platforms in applications made through the websites of our partners Vagas.com and LinkedIn. During the recruitment and selection process, the following data is collected: ",
            },
            "tabela-7": {
                "titulo": "Data categories",
                "titulo-item-1": "Name and Initials ",
                "descricao-item-1": " - Full name",
                "titulo-item-2": "Personal Characteristics ",
                "descricao-item-2": "  - Marital Status {'|'} Sex {'|'} Age",
                "titulo-item-3": "Home Information  ",
                "descricao-item-3": " - Home Address {'|'} Home Phone {'|'} Personal Mobile Number {'|'} Personal email",
                "titulo-item-4": "Professional information ",
                "descricao-item-4": " - Occupation/Title {'|'} Employment history declared by the employee {'|'} Employment history obtained through analysis / exchange of information",
            },
            "tabela-8": {
                "titulo": "Goal",
                "titulo-item-1": "Recruitment and Selection ",
                "descricao-item-1": "during this process will be collected information 	necessary for us to perform: ",
                "lista-1": "Contact confirming interest in the vacancy, confirmation of professional experience and personal data;",
                "lista-2": "The entries in the internal systems, enabling the interview and selection process;",
                "lista-3": "Analysis of the candidate's suitability for the vacancy profile, checking skills, abilities, previous experiences and education;",
                "lista-4": "Sharing data with managers of vacancies for validating the candidate's profile;",
                "lista-5": "Analysis of the documents and preparation of the proposal to the candidate.",
            },
            "bloco-7": {
                "paragrafo-1": "After acceptance by the candidate of the proposal made by Vigor, more information about the candidate will be collected so that the internal processes are carried out, making the hiring possible. The description of this data processing can be found in our ",
                "paragrafo-2": "Internal Privacy Policy – POL.CO.JUR.PRIV-004, ",
                "paragrafo-3": "available to all employees and service providers.",
                "paragrafo-4": "Within the scope of recruitment and selection activities, including candidacies and integration, special category data may also be collected from candidates, in cases where there is a legal obligation to do so, when permitted by applicable law, Vigor will collect information about deficiencies of an individual for workforce diversity analysis.",
                "paragrafo-5": "Vigor's recruitment tools and Pages contain privacy notices explaining why and how personal data is collected and processed by these Pages. Vigor encourages individuals who use Vigor's recruitment tools and Pages to review available privacy notices.",
                "paragrafo-6": "Vigor collects personal data about candidates from the following sources: ",
                "lista-1": {
                    "item-titulo-1": "From recruitment and selection platforms:  ",
                    "item-descricao-1": "for example, information that the Individual provided when applying for a vacancy directly through Vigor's careers website;",
                    "item-titulo-2": "Directly from the Personal Data Holder: ",
                    "item-descricao-2": "during the selection process when requested information to the holder;",
                    "item-titulo-3": "By reference: ",
                    "item-descricao-3": "For example, through a referral from a former employee or employer.",
                },
                "paragrafo-7": "The legal grounds for processing jobseekers' personal data are: ",
                "lista-2": {
                    "item-titulo-1": "Consent:  ",
                    "item-descricao-1": "Candidate's explicit ",
                    "item-titulo-2": "Legitimate interest: 1",
                    "item-descricao-2": " - in attracting, identifying and seeking talent. 2- Processing and managing applications for positions at Vigor, including candidate selection. 3- Legitimate interest in managing Vigor's career sites (including performing statistical analyses).",
                    "item-titulo-3": "Pre/Employment Execution ",
                    "item-descricao-3": " In hiring and onboarding candidates, making an offer to successful candidates, and performing pre-employment screening checks",
                },
            },
            "bloco-8": {
                "titulo": "Providers",
                "descricao": "Vigor processes personal data of representatives of its suppliers (including subcontractors and individuals associated with suppliers and contractors) to manage the relationship and performance of this contract. In order for us to carry out our relationship with the supplier, the following personal data will be processed: "
            },
            "tabela-9": {
                "titulo": "Data categories",
                "titulo-item-1": "Name and initials ",
                "descricao-item-1": " - First Name {'|'} Middle name {'|'} Last name;",
                "titulo-item-2": "Personal Characteristics  ",
                "descricao-item-2": " - Date and place of birth;",
                "titulo-item-3": "Identification generated by official bodies  ",
                "descricao-item-3": " - CPF {'|'} RG {'|'} CNH {'|'} PIS/PASEP;",
                "titulo-item-4": "Professional Information ",
                "descricao-item-4": " - Business Address {'|'} Business Phone {'|'} Business cell phone {'|'} Business email;",
                "titulo-item-5": "Financial Information ",
                "descricao-item-5": " - Banking Data (Bank, Branch and Account) – Applicable to MEI;",
                "titulo-item-6": "Sensitive general information ",
                "descricao-item-6": " – Photographs.",
            },
            "tabela-10": {
                "titulo": "Goal",
                "titulo-item-1": "Due Dilligence",
                "descricao-item-1": " - Prior to hiring, independent audits and other background checks required by law or regulation are conducted, for example, adverse media, bribery and corruption, and other financial crime checks.",
                "titulo-item-2": "Registration in our systems ",
                "descricao-item-2": "so that we can perform: ",
                "lista-1": "Elaboration/revision of the contractual draft;",
                "lista-2": "Management and realization of payments due to suppliers;",
                "lista-3": "Contract and commercial relationship management;",
                "lista-4": "Internal processes for approval and purchase release;",
                "lista-5": "issuing invoices or other documents taxes required by law.",
                "titulo-item-3": "Audit: ",
                "descricao-item-3": "The personal data of suppliers' legal representatives may be processed to prevent fraud and guarantee you security in supplying VIGOR.",
            },
            "bloco-9": {
                "titulo": "The legal grounds for processing personal data from suppliers are: ",
                "lista-1": "Contract Enforcement: ",
                "lista-2": "To perform the necessary pre-contractual steps to sign the contract, manage payments, fees and charges, and collect and recover money owed to Vigor;",
                "lista-3": "Compliance with a legal or regulatory obligation: ",
                "lista-4": "when we are required by law to collect that data.",
            },
            "bloco-10": {
                "titulo": "Customers",
                "descricao": "Vigor treats personal data of representatives of its customers who sell the products of our brands as follows: ",
            },
            "tabela-11": {
                "titulo": "Data categories",
                "titulo-item-1": "Name and initials ",
                "descricao-item-1": " - First Name {'|'} Middle name {'|'} Last name;",
                "titulo-item-2": "Personal Characteristics  ",
                "descricao-item-2": " - Date and place of birth;",
                "titulo-item-3": "Identification generated by official bodies  ",
                "descricao-item-3": " -CPF🇧🇷 RG {'|'} CNH {'|'} PIS/PASEP;",
                "titulo-item-4": "Professional Information ",
                "descricao-item-4": " - Business Address {'|'} Business Phone {'|'} Business cell phone {'|'} Business email;",
                "titulo-item-5": "Financial Information ",
                "descricao-item-5": " - Banking Data (Bank, Branch and Account) ",
                "descricao-item-6": " * will only be collected from the PF in the case of MEI ",
                "descricao-item-7": " {'|'} Credit score {'|'} Monthly household income and equity.",
            },
            "tabela-12": {
                "titulo": "Goal",
                "titulo-item-1": "Customer prospecting ",
                "descricao-item-1": " – Professional contact information is used for commercial approach and initiation of the relationship with VIGOR;",
                "titulo-item-2": "Due Dilligence",
                "descricao-item-2": " - Prior to hiring, independent audits and other background checks required by law or regulation are conducted, for example, adverse media, bribery and corruption, and other financial crime checks.",
                "titulo-item-3": "Registration in our systems  ",
                "descricao-item-3": "so that we can perform: ",
                "lista-1": "Elaboration/revision of the contractual draft;",
                "lista-2": "Management and realization of payments due to suppliers;",
                "lista-3": "Contract and commercial relationship management;",
                "lista-4": "Internal processes for approval and purchase release;",
                "lista-5": "issuing invoices or other tax documents required by law.",
                "titulo-item-4": "Strategic decision-making ",
                "descricao-item-4": " - analysis of customers' conditions to better understand their sales potential and that of VIGOR's brands, and guide the company's business and investments.",
                "titulo-item-5": "Granting of Credit ",
                "descricao-item-5": " - whenever the Customer needs a credit line for a purchase with Vigor, personal data will be processed for decision making.",
                "titulo-item-6": "Audit ",
                "descricao-item-6": " - customers' personal data may be processed to prevent fraud and guarantee you security in your relationship with VIGOR.",
            },
            "bloco-11": {
                "titulo": "The legal grounds for processing personal data from suppliers are: ",
                "lista-1": "Contract Enforcement: ",
                "lista-2": "To perform the necessary pre-contractual steps to sign the contract, manage payments, fees and charges, and collect and recover money owed to Vigor;",
                "lista-3": "Compliance with a legal or regulatory obligation:  ",
                "lista-4": "when we are required by law to collect that data;",
                "lista-5": "Legitimate interest:  1 ",
                "lista-6": " - to maintain commercial contact with the representatives of our Customers, 2- in the analysis of sales potential by area and strategic decision-making.",
            },
            "bloco-12": {
                "titulo": "Visitors to Vigor's Facilities",
                "descricao": "When you visit Vigor's office and industries, your data is collected and processed as follows: ",
            },
            "tabela-13": {
                "titulo": "Data categories",
                "titulo-item-1": "Name and initials ",
                "descricao-item-1": " - First Name {'|'} Middle name {'|'} Last name;",
                "titulo-item-2": "Personal Characteristics  ",
                "descricao-item-2": " - Date and place of birth;",
                "titulo-item-3": "Identification generated by official bodies  ",
                "descricao-item-3": " - CPF {'|'} RG {'|'} CNH and CTPS;",
                "titulo-item-4": "Sensitive general information ",
                "descricao-item-4": " – Images from surveillance cameras.",
            },
            "tabela-14": {
                "titulo": "Goal",
                "titulo-item-1": "Access Control ",
                "descricao-item-1": " - In order to provide you with certain facilities such as access to our buildings and conference rooms or Wi-Fi and to control access to our buildings.",
                "titulo-item-2": "Asset Security ",
                "descricao-item-2": " - to protect our offices, staff, property and confidential information eg using CCTV.",
            },
            "bloco-13": {
                "titulo": "The legal grounds for processing personal data from suppliers are: ",
                "descricao": "in maintaining access control and traceability of the people who had access to Vigor's facilities, protecting the physical integrity of our employees and our business secrets.",
            },
            "bloco-14": {
                "titulo": "Data Sharing",
                "paragrafo-1": "Vigor may share the personal data that is collected with third-party service providers, who are hired by Vigor to support internal auxiliary processes.",
                "paragrafo-2": "Only service providers/third parties are used that are required to maintain adequate levels of data protection, security and confidentiality, and that comply with all applicable legal requirements for the international transfer of personal data.",
                "paragrafo-3": "Vigor may share the personal data collected from the Holders: ",
                "lista-1": {
                    "item-1": "When appropriate to achieve the purposes described in item ",
                    "item-2": "6.2 What Data We Collect and Its Purposes ;",
                    "item-3": "If determined by applicable law;",
                    "item-4": "In order to comply with a court order or other legal obligation, or a regulatory or governmental inquiry;",
                    "item-5": "In cases of corporate restructuring with the merger, spin-off or incorporation of Vigor;",
                    "item-6": "For the regular exercise of Vigor's rights under the LGPD;",
                    "item-7": "Or with the consent of the Holder of the personal data.",
                },
                "paragrafo-4": "Third party recipients of personal data include: ",
                "lista-2": {
                    "item-1": "Professional consultants, such as law firms, tax consultants or auditors;",
                    "item-2": "Insurers;",
                    "item-3": "Tax, customs and excise duty authorities;",
                    "item-4": "Regulatory bodies and other professional bodies;",
                    "item-5": "Courts of Justice;",
                    "item-6": "Financial Institution;",
                    "item-7": "Government departments and agencies;",
                    "item-8": "Service providers.",
                }
            },
            "bloco-15": {
                "titulo": "Safety",
                "paragrafo-1": "Vigor protects the confidentiality, integrity and availability of the information it obtains in the course of its business. Access to such information is limited and policies and procedures are in place to protect the information from loss, misuse and improper disclosure.",
                "paragrafo-2": "Technical standards are adopted that are compatible with the best market practices to ensure the security and protection of personal data in Vigor's processes. Measures to prevent security incidents include physical and logical protection of assets, encrypted communications, access management and internal auditing policies.",
                "paragrafo-3": "In addition to processing personal data in accordance with the provisions of this policy, anonymized data is also processed, which means that that set of information can no longer identify a person. Also, personal data is processed in a pseudo-anonymized format, where personal data is submitted to a process whereby it loses the possibility of association, direct or indirect, only with additional use kept in a separate and secure environment.",
            },
            "bloco-16": {
                "titulo": "Incident Notification",
                "paragrafo-1": "Vigor assumes the premise of security and compliance with the General Data Protection Law in its processes and platforms. Having defined policies, processes and controls to ensure the integrity and protection of personal data, including security strategies adopted to prevent unauthorized access to our databases.",
                "paragrafo-2": "If we become aware of any security incident, we will, whenever necessary, inform you of this event, in accordance with our information security policies and the legal and/or contractual requirements in force.",
            },
            "bloco-17": {
                "titulo": "International Data Transfer",
                "paragrafo-1": "Vigor transfers personal data collected in Brazil to servers located abroad, being carried out only for Vigor Partners who work in the processing of personal data, and these transfers only involve companies that have demonstrated compliance with the LGPD, having adopted the necessary safeguards to carry it out, in accordance with The provisions of article 33 of the LGPD.",
            },
            "bloco-18": {
                "titulo": "Retention",
                "paragrafo-1": "Vigor keeps personal data only as long as it is necessary to fulfill the purposes for which it was collected. Retention periods vary in different jurisdictions and are defined in accordance with local regulatory and professional retention requirements.",
                "paragrafo-2": "To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of potential security incidents that may occur, the purpose of processing your personal data and whether we can achieve such purposes through other means, as well as applicable legal requirements.",
                "paragrafo-3": "In order to meet professional and legal requirements, establish, exercise or defend legal rights, and for archival and historical purposes, Vigor needs to maintain information for various periods, as stated in its internal policies.",
                "titulo-2": "Your Rights and How to Exercise Them"
            },
            "tabela-15": {
                "titulo": "data categories",
                "titulo-2": "Goal",
                "titulo-item-1": "Treatment confirmation",
                "descricao-item-1": "You can request VIGOR to confirm the existence of processing of your personal data.",
                "titulo-item-2": "Data access",
                "descricao-item-2": "You can ask VIGOR to access the personal data that we have related to you.",
                "titulo-item-3": "Correction of personal data",
                "descricao-item-3": "You can request VIGOR to correct data that are incomplete, inaccurate or out of date, and may correct or supplement them. To carry out the correction, we may require that you present a document proving the veracity of the new data entered.",
                "titulo-item-4": "Anonymization, blocking or deletion",
                "descricao-item-4": "This right allows YOU to ask us to anonymize, block or delete your personal data in the following scenarios: (a) data unnecessary personal; (b) excessive personal data; or (c) personal data processed in violation of the LGPD.",
                "titulo-item-5": "Deletion 	of 	data 	processed 	with consent",
                "descricao-item-5": "You can request the deletion of data processed based on your consent, which will be deleted. Only data that is necessary for the provision of our services or for the fulfillment of legal obligations or regulatory.",
                "titulo-item-6": "Information about sharing",
                "descricao-item-6": "You can ask VIGOR for information from public entities and private with which your data has been shared.",
                "titulo-item-7": "Information on the possibility of not providing consent and on the consequences of the refusal",
                "descricao-item-7": "If 	your consent is essential to 	access a certain VIGOR product or service, you can ask us to information about the possibility of not providing consent and what are the negative consequences arising from this action",
                "titulo-item-8": "Revocation of consent",
                "descricao-item-8": "If you have given your consent to data processing, you can withdraw it at any time. We emphasize that this does not mean that we can no longer process your data, these may be treated anonymously or based on another legal hypothesis that supports the treatment.",
                "titulo-item-9": "Data portability",
                "descricao-item-9": "After the regulation of this right by the competent authority under the LGPD, you will be able to request the portability of your personal data to another service or product provider.",
            },
            "bloco-19": {
                "titulo": "1.1 Contact Vigor",
                "paragrafo-1": "If you want to exercise any of the rights set out above or have any questions regarding the processing of personal data, please contact us through our website",
                "paragrafo-2": "or directly with our Data Controller by email",
                "paragrafo-3": "It may be necessary to request specific information from you to help us confirm your identity, for your own protection. Also, we may reject requests if they are unlawful.",
                "paragrafo-4": "To exercise any right, you will need to make an express request to Vigor, which you can do through the website",
                "paragrafo-5": "If you have any questions, feel free to contact us via email",
                "paragrafo-6": "If you need any assistance to exercise your rights, the User may contact Vigor, as per guidelines in this Policy.",
            },
            "botao-politica-qualidade": "Quality Policy",
        },
        //apagar
        "nossa-cultura": {
            "titulo": "Our passion is to feed your life",
            "paragrafo-2": "We believe that our result comes from our dedication! To get here it was necessary to establish solid pillars, with the certainty that we have the best people working with us.",
            "paragrafo-3": "Our people turn obstacles into opportunities and are challenged to give their best in a dynamic environment full of challenges.",
            "paragrafo-4": "We seek people who share our values and add to our culture. It translates our way of being and guides us daily, in decisions, in the search for professionals, and in the business strategy.",
            "paragrafo-5": "Our culture is grounded by a clear mission and strong values, which we live and constantly seek to improve upon.",
            "paragrafo-6": "We believe in success empowered by people who enrich our culture, live our values, and contribute to the purpose of feeding more delicious lives day after day.",
            "titulo-2": "Mission",
            "paragrafo-7": "To be the best in what we propose to do, with absolute focus on our activities, ensuring the best products and services to customers, solidity to suppliers, profitability to shareholders and the possibility of a better future to all our employees.",
            "titulo-3": "Values",
            "nossa-cultura": "Our culture",
            "investidores": "Investor relations",
        },
    }
}
