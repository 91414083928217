import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e734444"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  class: "d-none d-md-block",
  viewBox: "0 0 1440 61",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_3 = ["fill"]
const _hoisted_4 = {
  class: "d-block d-md-none",
  viewBox: "0 0 360 34",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_5 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("path", {
        d: "M0 0V61H1440V0C1440 0 1273.26 42.5316 720 42.5316C96 42.5316 0 0 0 0Z",
        fill: _ctx.color || '#fff'
      }, null, 8, _hoisted_3)
    ])),
    (_openBlock(), _createElementBlock("svg", _hoisted_4, [
      _createElementVNode("path", {
        d: "M0 0V34H360V0C360 0 318.316 23.7062 180 23.7062C24 23.7062 0 0 0 0Z",
        fill: _ctx.color || '#fff'
      }, null, 8, _hoisted_5)
    ]))
  ]))
}