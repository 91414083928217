import IBanner from "@/interfaces/IBanner";
import { defineStore } from 'pinia'
import axios from "axios";

interface State {
    allBanners: IBanner[],
    singleBanner: IBanner[],
    apiUrl: string,
}

export const useBannerStore = defineStore('useBannerStore', {
    state: (): State => ({
        allBanners: [],
        singleBanner: [],
        apiUrl: '/api/banners',
    }),
    actions: {
        async getBanner(id: string) {
            await axios.get(this.apiUrl + '/' + id)
                .then((response) => this.allBanners = response.data.data)
        },
        async getBannerPage(parameters: string, language: string) {
            await axios.get(this.apiUrl + parameters, { headers: { 'Accept-Language': language, }, })
                .then((response) => this.allBanners = response.data.data)
        },
    }
})