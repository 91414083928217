import { defineStore } from 'pinia'
import axios from "axios";
import IBrand from "@/interfaces/IBrand";

interface State {
    allBrands: IBrand[],
    singleBrand: IBrand,
    apiUrl: string,
}

export const useBrandsStore = defineStore('useBrandsStore', {
    state: (): State => ({
        allBrands: [],
        singleBrand: {} as IBrand,
        apiUrl: '/api/brands',
    }),
    actions: {
        async getBrands() {
            await axios.get(this.apiUrl)
                .then((response) => this.allBrands = response.data.data)
        },
        async getBrand(id: string, language: string) {
            await axios.get(this.apiUrl + '/slug/' + id, { headers: { 'Accept-Language': language, }, })
                .then((response) => this.singleBrand = response.data.data)
        },
    }
})