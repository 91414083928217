
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { useRoute } from 'vue-router';

export default defineComponent({
	name: "App",
	setup() {
		// const route = useRoute();

		useMeta({
			title: "Vigor",
			htmlAttrs: { lang: "PT-br", amp: true },
			description: "A Vigor oferece os mais variados e deliciosos produtos para agradar toda a família. Visite o site e veja nossas linhas, receitas, notícias e iniciativas.",
			// link: [
			// 	{ rel: 'canonical', href: "https://www.vigor.com.br" + route.fullPath },
			// ],
			og: {
				site_name: "Vigor Alimentos",
				url: "http://www.vigor.com.br/",
				title: "Vigor",
				description: "A Vigor oferece os mais variados e deliciosos produtos para agradar toda a família. Visite o site e veja nossas linhas, receitas, notícias e iniciativas.",
				// image: ["https://picsum.photos/600/400/?image=80", "https://picsum.photos/600/400/?image=82"],
			},
			// script: [{ src: "https://consent.cookiefirst.com/banner.js",  "data-cookiefirst-key":"e7592b24-7392-48c5-8ed4-2f56a014a55a" }],
		});

	},
});
