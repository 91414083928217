import { defineStore } from 'pinia'

interface State {
    imagesBaseUrl: string,
    language: string
}

const localStorage = window.localStorage.getItem('language')

export const usePiniaStore = defineStore('usePiniaStore', {
    state: (): State => ({
        imagesBaseUrl: process.env.VUE_APP_BASE_REQUEST_URL + '/storage/',
        language: localStorage+'' || 'pt'
    }),
    actions: {
        setLanguage(language: string) { 
            this.language = language
        },
    }
})