import { createI18n } from 'vue-i18n'
import { ptLocale } from '../locales/pt'
import { enLocale } from '../locales/en'

const messages = {
    "pt": ptLocale.pt,
    "en": enLocale.en,
}

const localStorage =  window.localStorage.getItem('language')

export const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: localStorage || 'pt', // set locale
    fallbackLocale: "pt", // set fallback locale
    messages, // set locale messages
})
