import { createApp  } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory, RouteRecordRaw  } from "vue-router";
import { routes } from './router/index'
import { createPinia } from 'pinia'
import { createMetaManager } from 'vue-meta'
import { i18n } from './plugins/i18n'
import './http/index'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@/assets/css/main.css'

import {maska, tokens} from 'maska'
tokens.X = { pattern: /./ };

// Vue Router
const router = createRouter({
    history: createWebHistory(),
    routes: routes as RouteRecordRaw[],
    scrollBehavior() {
        return { top: 0 }
    },
});

createApp(App)
    .use(router)
    .use(i18n)
    .use(createMetaManager())
    .directive("mask", maska)
    .use(createPinia())
    .mount('#app')
