import { defineStore } from 'pinia'
import axios from "axios";
import ISearch from '@/interfaces/ISearch';

interface State {
    allSearch: ISearch[],
    apiUrl: string,
}

export const useSearchStore = defineStore('useSearchStore', {
    state: (): State => ({
        allSearch: [],
        apiUrl: '/api/search',
    }),
    actions: {
        async postSearch(search: any, language: string) {
            await axios.post(this.apiUrl, search , { headers: { 'Accept-Language': language, }, })
                .then((response) => this.allSearch = response.data.data)
        },
    }
})