import { defineStore } from 'pinia'
import axios from "axios";

interface State {
    apiUrl: string,
}

export const useNewsletterStore = defineStore('useNewsletterStore', {
    state: (): State => ({
        apiUrl: '/api/newsletters',
    }),
    actions: {
        async postNewsletter(form: any) {
            await axios.post(this.apiUrl, form)
        },
    }
})