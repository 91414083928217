<template>
    <a v-if="isExternal" :href="to" target="_blank"><slot/></a>
    <router-link v-else v-bind="$props"><slot /></router-link>
</template>


<script>
import { RouterLink } from 'vue-router'
import { defineComponent } from 'vue'

export default defineComponent({
    name: "App Link",
    props: {
        ...RouterLink.props
    },
    computed: {
        isExternal(){
            return typeof this.to === 'string' && this.to.startsWith('http')
        }
    }
})
</script>
